import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useMemo } from 'react';
import { Icon, Input, Space, Size } from '@hse-design/react';
import { IconActionSearch, IconGlobalPhoneDefault } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { ComponentLinks, FigmaEmbed } from '../common';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "input"
    }}>{`Input`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { Input } from '@hse-design/react';
`}</code></pre>
    <ComponentLinks figma={'https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=313%3A79'} storybook={'/?path=/story/inputs-input--playground'} vueStorybook={'/?path=/story/inputs-input--playground'} name={'Input'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`Input – это компонент поля ввода. Может использоваться в формах или самостоятельно.`}</p>
    <p>{`У компонента 3 размера: `}<inlineCode parentName="p">{`small`}</inlineCode>{`, `}<inlineCode parentName="p">{`medium`}</inlineCode>{`, `}<inlineCode parentName="p">{`large`}</inlineCode>{`, по умолчанию используется размер `}<inlineCode parentName="p">{`medium`}</inlineCode>{`. Передайте в проп `}<inlineCode parentName="p">{`size`}</inlineCode>{` нужный.`}</p>
    <p>{`Input может находиться в состояниях `}<inlineCode parentName="p">{`disabled`}</inlineCode>{` или `}<inlineCode parentName="p">{`error`}</inlineCode>{`:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`disabled`}</inlineCode>{` отключенное состояние. В данном состоянии ввод текста запрещен`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`error`}</inlineCode>{` состояние ошибки. В данном состоянии border компонента становится красного цвета`}</p>
      </li>
    </ul>
    <p>{`Компоненту можно добавить иконки справа и слева. Передайте компонент иконки в проп `}<inlineCode parentName="p">{`leftIcon`}</inlineCode>{` или `}<inlineCode parentName="p">{`rightIcon`}</inlineCode>{`, либо заполните слоты `}<inlineCode parentName="p">{`left`}</inlineCode>{` или `}<inlineCode parentName="p">{`right`}</inlineCode>{`.`}</p>
    <p>{`При необходимости можно добавить маску для ввода в проп `}<inlineCode parentName="p">{`mask`}</inlineCode>{`. Возможные значения пропа находятся в `}<a parentName="p" {...{
        "href": "#%D0%BC%D0%B0%D1%81%D0%BA%D0%B0"
      }}>{`примере ниже`}</a></p>
    <p>{`Input может занимать 100% ширины родительского элемента. Для этого передайте проп `}<inlineCode parentName="p">{`fullWidth`}</inlineCode>{`.`}</p>
    <p>{`Для добавления лейбла и сообщения ошибки используйте компонент `}<a parentName="p" {...{
        "href": "/src-components-rich-input"
      }}>{`RichInput`}</a></p>
    <Playground __position={1} __code={'<Input placeholder=\"Ваше имя\" />'} __scope={{
      props,
      DefaultLayout,
      useMemo,
      Icon,
      Input,
      Space,
      Size,
      IconActionSearch,
      IconGlobalPhoneDefault,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Input placeholder="Ваше имя" mdxType="Input" />
    </Playground>
    <h2 {...{
      "id": "анатомия"
    }}>{`Анатомия`}</h2>
    <FigmaEmbed node="?node-id=9662:33979" mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "использование"
    }}>{`Использование`}</h2>
    <FigmaEmbed node="?node-id=9662:33984" mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "размер"
    }}>{`Размер`}</h3>
    <p>{`У компонента 3 размера: `}<inlineCode parentName="p">{`small`}</inlineCode>{`, `}<inlineCode parentName="p">{`medium`}</inlineCode>{`, `}<inlineCode parentName="p">{`large`}</inlineCode>{`, по умолчанию используется размер `}<inlineCode parentName="p">{`medium`}</inlineCode>{`. Передайте в проп `}<inlineCode parentName="p">{`size`}</inlineCode>{` нужный.`}</p>
    <Playground __position={4} __code={'<Input placeholder=\"Small\" size={Input.Size.small} />\n<Space size={Size.small_2x} vertical />\n<Input placeholder=\"Medium\" />\n<Space size={Size.small_2x} vertical />\n<Input placeholder=\"Large\" size={Input.Size.large} />'} __scope={{
      props,
      DefaultLayout,
      useMemo,
      Icon,
      Input,
      Space,
      Size,
      IconActionSearch,
      IconGlobalPhoneDefault,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Input placeholder="Small" size={Input.Size.small} mdxType="Input" />
  <Space size={Size.small_2x} vertical mdxType="Space" />
  <Input placeholder="Medium" mdxType="Input" />
  <Space size={Size.small_2x} vertical mdxType="Space" />
  <Input placeholder="Large" size={Input.Size.large} mdxType="Input" />
    </Playground>
    <h3 {...{
      "id": "отключенное-состояние"
    }}>{`Отключенное состояние`}</h3>
    <p>{`Передайте проп `}<inlineCode parentName="p">{`disabled`}</inlineCode>{` для отключения ввода текста`}</p>
    <Playground __position={5} __code={'<Input placeholder=\"Ввод текста отключен\" disabled />'} __scope={{
      props,
      DefaultLayout,
      useMemo,
      Icon,
      Input,
      Space,
      Size,
      IconActionSearch,
      IconGlobalPhoneDefault,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Input placeholder="Ввод текста отключен" disabled mdxType="Input" />
    </Playground>
    <h3 {...{
      "id": "состояние-ошибки"
    }}>{`Состояние ошибки`}</h3>
    <p>{`Добавить состояние ошибки можно с помощью пропа `}<inlineCode parentName="p">{`error`}</inlineCode>{`. В данном состоянии border компонента становится красного цвета`}</p>
    <p>{`Для добавления сообщения ошибки используйте компонент `}<a parentName="p" {...{
        "href": "/src-components-rich-input"
      }}>{`RichInput`}</a></p>
    <Playground __position={6} __code={'<Input placeholder=\"Что-то пошло не так\" error />'} __scope={{
      props,
      DefaultLayout,
      useMemo,
      Icon,
      Input,
      Space,
      Size,
      IconActionSearch,
      IconGlobalPhoneDefault,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Input placeholder="Что-то пошло не так" error mdxType="Input" />
    </Playground>
    <h3 {...{
      "id": "иконки"
    }}>{`Иконки`}</h3>
    <p>{`Компоненту можно добавить иконки справа и слева. Передайте компонент иконки в проп `}<inlineCode parentName="p">{`leftIcon`}</inlineCode>{` или `}<inlineCode parentName="p">{`rightIcon`}</inlineCode>{`, либо заполните слоты `}<inlineCode parentName="p">{`left`}</inlineCode>{` или `}<inlineCode parentName="p">{`right`}</inlineCode>{`.`}</p>
    <p>{`При использовании слотов следует передавать корректный размер иконки. Для Input размера `}<inlineCode parentName="p">{`small`}</inlineCode>{` необходимо передать иконку размера `}<inlineCode parentName="p">{`small`}</inlineCode>{`, для Input размера `}<inlineCode parentName="p">{`medium`}</inlineCode>{` и выше нужно передать иконку размера `}<inlineCode parentName="p">{`medium`}</inlineCode>{`. При использовании пропов `}<inlineCode parentName="p">{`leftIcon`}</inlineCode>{` и `}<inlineCode parentName="p">{`rightIcon`}</inlineCode>{` размер иконки будет автоматически выставлен.`}</p>
    <Playground __position={7} __code={'<Input placeholder=\"Иконка слева\" leftIcon={IconActionSearch} />\n<Space size={Size.small_2x} vertical />\n<Input placeholder=\"Иконка справа\" rightIcon={IconGlobalPhoneDefault} />\n<Space size={Size.small_2x} vertical />\n<Input\n  placeholder=\"Иконки с двух сторон\"\n  leftIcon={IconActionSearch}\n  rightIcon={IconGlobalPhoneDefault}\n/>'} __scope={{
      props,
      DefaultLayout,
      useMemo,
      Icon,
      Input,
      Space,
      Size,
      IconActionSearch,
      IconGlobalPhoneDefault,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Input placeholder="Иконка слева" leftIcon={IconActionSearch} mdxType="Input" />
  <Space size={Size.small_2x} vertical mdxType="Space" />
  <Input placeholder="Иконка справа" rightIcon={IconGlobalPhoneDefault} mdxType="Input" />
  <Space size={Size.small_2x} vertical mdxType="Space" />
  <Input placeholder="Иконки с двух сторон" leftIcon={IconActionSearch} rightIcon={IconGlobalPhoneDefault} mdxType="Input" />
    </Playground>
    <h3 {...{
      "id": "маска"
    }}>{`Маска`}</h3>
    <p>{`Для добавления маски ввода используйте проп `}<inlineCode parentName="p">{`mask`}</inlineCode>{`.`}</p>
    <p>{`Значением пропа может быть как строка, так и `}<a parentName="p" {...{
        "href": "https://github.com/RobinHerbots/Inputmask#options"
      }}>{`объект с настройками InputMask`}</a>{`.`}</p>
    <p>{`Если в `}<inlineCode parentName="p">{`mask`}</inlineCode>{` передается объект, то рекомендуется обеспечить его ссылочную постоянность (чтобы проп не менялся каждый рендер компонента).
Вы можете вынести настройки константой за пределы компонента, либо использовать `}<inlineCode parentName="p">{`useMemo`}</inlineCode>{`.`}</p>
    <p>{`Для номеров телефонов РФ (+7) используйте значение `}<inlineCode parentName="p">{`phone`}</inlineCode>{`, для дат – `}<inlineCode parentName="p">{`date`}</inlineCode>{`.`}</p>
    <p>{`Все возможные значения пропа `}<inlineCode parentName="p">{`mask`}</inlineCode>{` можно посмотреть `}<a parentName="p" {...{
        "href": "https://github.com/RobinHerbots/Inputmask#options"
      }}>{`в документации InputMask`}</a></p>
    <Playground __position={8} __code={'<div>\n  <Input placeholder=\"Введите номер телефона\" mask=\"phone\" />\n  <Space size=\"small_x\" vertical />\n  <Input placeholder=\"Введите дату\" mask=\"date\" />\n  <Space size=\"small_x\" vertical />\n  <Input placeholder=\"Введите e-mail\" mask=\"email\" />\n  <Space size=\"small_x\" vertical />\n  <Input placeholder=\"Введите URL\" mask=\"url\" />\n  <Space size=\"small_x\" vertical horizontal />\n</div>'} __scope={{
      props,
      DefaultLayout,
      useMemo,
      Icon,
      Input,
      Space,
      Size,
      IconActionSearch,
      IconGlobalPhoneDefault,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div>
    <Input placeholder="Введите номер телефона" mask="phone" mdxType="Input" />
    <Space size="small_x" vertical mdxType="Space" />
    <Input placeholder="Введите дату" mask="date" mdxType="Input" />
    <Space size="small_x" vertical mdxType="Space" />
    <Input placeholder="Введите e-mail" mask="email" mdxType="Input" />
    <Space size="small_x" vertical mdxType="Space" />
    <Input placeholder="Введите URL" mask="url" mdxType="Input" />
    <Space size="small_x" vertical horizontal mdxType="Space" />
  </div>
    </Playground>
    <p>{`Дополнительно доступна маска `}<inlineCode parentName="p">{`date`}</inlineCode>{`, позволяющая ввести только день без времени.`}</p>
    <Playground __position={9} __code={'() => {\n  const mask = useMemo(\n    () => ({\n      alias: \'date\',\n      min: \'01.01.2000\',\n      max: \'31.12.2022\',\n    }),\n    [],\n  )\n  return <Input placeholder=\"Введите дату\" mask={mask} />\n}'} __scope={{
      props,
      DefaultLayout,
      useMemo,
      Icon,
      Input,
      Space,
      Size,
      IconActionSearch,
      IconGlobalPhoneDefault,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const mask = useMemo(() => ({
          alias: 'date',
          min: '01.01.2000',
          max: '31.12.2022'
        }), []);
        return <Input placeholder="Введите дату" mask={mask} mdxType="Input" />;
      }}
    </Playground>
    <h3 {...{
      "id": "полная-ширина"
    }}>{`Полная ширина`}</h3>
    <p>{`Input может занимать 100% ширины родительского элемента. Для этого передайте проп `}<inlineCode parentName="p">{`fullWidth`}</inlineCode></p>
    <Playground __position={10} __code={'<Input placeholder=\"Широкий\" fullWidth />'} __scope={{
      props,
      DefaultLayout,
      useMemo,
      Icon,
      Input,
      Space,
      Size,
      IconActionSearch,
      IconGlobalPhoneDefault,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Input placeholder="Широкий" fullWidth mdxType="Input" />
    </Playground>
    <h2 {...{
      "id": "пропы"
    }}>{`Пропы`}</h2>
    <Props of={Input} mdxType="Props" />
    <p>{`Все остальные пропы пробрасываются на элемент `}<inlineCode parentName="p">{`input`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "css-классы"
    }}>{`CSS классы`}</h2>
    <p>{`Вы можете использовать данный компонент без библиотеки компонентов, а лишь подключив css стили. Ниже приведен пример использования. Компоненты сверстаны по методологии `}<a parentName="p" {...{
        "href": "https://bem.info/"
      }}>{`BEM`}</a>{`: миксуя необходимые модификаторы вы можете задать компоненту определенное состояние.`}</p>
    <p>{`Класс блока: `}<inlineCode parentName="p">{`hse-Input`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "модификаторы"
    }}>{`Модификаторы`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Модификатор`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Описание`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Input_size_small`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Маленький размер инпута`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Input_size_medium`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Средний размер инпута`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Input_size_large`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Большой размер инпута`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Input_focus`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Состояние focus у инпута`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Input_error`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Состояние error у инпута`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Input_disabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Делает инпут неработоспособным`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Input_fullWidth`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Ширина инпута 100%`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "элементы"
    }}>{`Элементы`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Элемент`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Описание`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Input__icon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Иконка слева или справа`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Input__input`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Инпут`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "модификаторы-элементов"
    }}>{`Модификаторы элементов`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Элемент`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Описание`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Input__icon_left`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Модификатор к слоту слева для иконки`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hse-Input__icon_right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Модификатор к слоту справа для иконки`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "пример-использования"
    }}>{`Пример использования`}</h3>
    <Playground __position={12} __code={'<div className=\"hse-Input hse-Input_size_medium hse-Input_focus hse-Input_fullWidth\">\n  <input className=\"hse-Input__input\" />\n  <div className=\"hse-Input__icon hse-Input__icon_right\">\n    <svg\n      width=\"1em\"\n      height=\"1em\"\n      viewBox=\"0 0 24 24\"\n      fill=\"none\"\n      xmlns=\"http://www.w3.org/2000/svg\"\n    >\n      <path\n        d=\"M16.84 14.8444C17.5973 13.707 18.0009 12.3709 18 11.0044C18.0021 9.44648 17.4844 7.93238 16.5289 6.70188C15.5733 5.47138 14.2347 4.59479 12.7248 4.21093C11.2149 3.82707 9.62007 3.95787 8.1929 4.58262C6.76573 5.20738 5.58776 6.29037 4.84552 7.66012C4.10328 9.02987 3.83919 10.6081 4.09508 12.1449C4.35096 13.6816 5.11219 15.0891 6.25823 16.1444C7.40426 17.1998 8.8696 17.8426 10.4222 17.9712C11.9748 18.0998 13.526 17.7068 14.83 16.8544L18 20.0044L20 18.0044L16.84 14.8444ZM6 11.0044C6 10.0155 6.29325 9.04879 6.84266 8.22655C7.39206 7.4043 8.17296 6.76344 9.08659 6.385C10.0002 6.00656 11.0056 5.90755 11.9755 6.10047C12.9454 6.2934 13.8363 6.7696 14.5355 7.46887C15.2348 8.16813 15.711 9.05904 15.9039 10.0289C16.0969 10.9989 15.9978 12.0042 15.6194 12.9178C15.241 13.8314 14.6001 14.6123 13.7779 15.1617C12.9556 15.7112 11.9889 16.0044 11 16.0044C9.67392 16.0044 8.40215 15.4776 7.46447 14.5399C6.52679 13.6023 6 12.3305 6 11.0044Z\"\n        fill=\"currentColor\"\n      />\n    </svg>\n  </div>\n</div>'} __scope={{
      props,
      DefaultLayout,
      useMemo,
      Icon,
      Input,
      Space,
      Size,
      IconActionSearch,
      IconGlobalPhoneDefault,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div className='hse-Input hse-Input_size_medium hse-Input_focus hse-Input_fullWidth'>
    <input className='hse-Input__input' />
    <div className='hse-Input__icon hse-Input__icon_right'>
	    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
	      <path d="M16.84 14.8444C17.5973 13.707 18.0009 12.3709 18 11.0044C18.0021 9.44648 17.4844 7.93238 16.5289 6.70188C15.5733 5.47138 14.2347 4.59479 12.7248 4.21093C11.2149 3.82707 9.62007 3.95787 8.1929 4.58262C6.76573 5.20738 5.58776 6.29037 4.84552 7.66012C4.10328 9.02987 3.83919 10.6081 4.09508 12.1449C4.35096 13.6816 5.11219 15.0891 6.25823 16.1444C7.40426 17.1998 8.8696 17.8426 10.4222 17.9712C11.9748 18.0998 13.526 17.7068 14.83 16.8544L18 20.0044L20 18.0044L16.84 14.8444ZM6 11.0044C6 10.0155 6.29325 9.04879 6.84266 8.22655C7.39206 7.4043 8.17296 6.76344 9.08659 6.385C10.0002 6.00656 11.0056 5.90755 11.9755 6.10047C12.9454 6.2934 13.8363 6.7696 14.5355 7.46887C15.2348 8.16813 15.711 9.05904 15.9039 10.0289C16.0969 10.9989 15.9978 12.0042 15.6194 12.9178C15.241 13.8314 14.6001 14.6123 13.7779 15.1617C12.9556 15.7112 11.9889 16.0044 11 16.0044C9.67392 16.0044 8.40215 15.4776 7.46447 14.5399C6.52679 13.6023 6 12.3305 6 11.0044Z" fill="currentColor" />
	    </svg>
    </div>
  </div>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      